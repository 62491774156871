import Glide, {
    Autoplay,
    Breakpoints,
    Controls,
    Swipe
} from "@glidejs/glide/dist/glide.modular.esm";
import { loadBloc } from "../../assets/js/utils";

(($) => {
    let options = {
        type: "carousel",
        //autoplay: 4000,
        gap: 0,
        startAt: 0,
        perView: 3,
        breakpoints: {
            767: {
                perView: 2,
            },
            639: {
                perView: 1,
            }
        }
    };

    function listingSlider() {
        $('.listing .glide').each(function (){
            let slider = new Glide($(this)[0], options);
            slider.mount({
                Autoplay,
                Breakpoints,
                Controls,
                Swipe
            });
        });
    }
    loadBloc(listingSlider);
})(jQuery);